import React from 'react';
import WeddingDetails from '../components/weddingDetails';

const DetailsPage = () => {
  return (
    <>
      <h1 className='heading'>Wedding Details</h1>
      <WeddingDetails />
      <section>
        <p>
          Both the ceremony and reception will be held at The Atrium in Prospect, PA (near Butler).
          The ceremony will be in a beautiful outdoor gazebo garden, with cocktail hour and
          reception to follow indoors in the greenhouse and main hall spaces. We encourage our
          guests to wear cocktail attire and be ready to drink and dance the night away.
        </p>
      </section>
    </>
  );
};

export default DetailsPage;
